import PersonAddAltSharpIcon from "@mui/icons-material/PersonAddAltSharp";
import { useTranslation } from "react-i18next";
import { User } from "../../../api/userManagement";
import { useCustomNavigation } from "../../../hooks/useCustomNavigation";
import CustomCard from "../../common/CustomCard";
import UserList from "./UserList";

export type UserFormData = Omit<User, "id">;

export default function UserManagement() {
  const { t } = useTranslation();
  const { navigateToAddUser } = useCustomNavigation();

  return (
    <CustomCard
      cardId="pages-settings-manage-users"
      cardTitle={t("pages-settings-manage-users")}
      cardHasButton={true}
      cardButtonFunc={navigateToAddUser}
      cardButtonTitle={t("pages-settings-new-user")}
      cardButtonIcon={<PersonAddAltSharpIcon />}
      cardContent={<UserList />}
    />
  );
}
