import * as yup from "yup";
import { TranslationFunction } from "../helpers";

export const createAddProductSchema = (t: TranslationFunction) => {
  return yup.object().shape({
    description: yup.string(),
    id: yup.string(),
    inbound_logistics_type: yup.string(),
    sku: yup.string().required(t("pages-settings-validation-required")),
    supplier_id: yup.string().required(t("global-validation-required")),
    uom: yup.string().required(t("pages-settings-validation-required")),
  });
};
export type AddProductFormData = yup.InferType<
  ReturnType<typeof createAddProductSchema>
>;
