import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
  interface Palette {
    border: Palette["primary"];
  }
  interface PaletteOptions {
    border?: PaletteOptions["primary"];
  }
  interface TypeBackground {
    light: string;
    dark: string;
    white: string;
    black: string;
  }
}

// SETUP COLORS
const PRIMARY = "#97470F";
// const PRIMARY = "#002884";
const PRIMARY_LIGHTER = "#F2EAE0";
const PRIMARY_LIGHT = "#DFC0A5";
const PRIMARY_DARK = "#391800";
const SECONDARY = "#546E0F";
const SECONDARY_LIGHT = "#C1CB86";
const SECONDARY_DARK = "#546E0F";
const INFO = "#8ACCDA";
const SUCCESS = "#8BAF1A";
const WARNING = "#F9B036";
const BORDER_GREY = "#e0e0e0";
const BACKGROUND_LIGHT = "#f3f3f3";
const BACKGROUND_DARK = "#202020";
const BACKGROUND_WHITE = "#FFFFFF";
const BACKGROUND_BLACK = "#000000";

export const DRAWER_WIDTH = 550;

export const NAVBAR_WIDE_WIDTH = 270;
export const NAVBAR_SMALL_WIDTH = 90;
export const DESKTOP_CONTAINER_HEADER_HEIGHT = 80;
export const DESKTOP_CONTENT_CONTAINER_MAX_WIDTH = 1200;

let theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: PRIMARY,
      light: PRIMARY_LIGHT,
      lighter: PRIMARY_LIGHTER,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT,
      dark: SECONDARY_DARK,
    },
    info: {
      main: INFO,
    },
    success: {
      main: SUCCESS,
    },
    warning: {
      main: WARNING,
    },
    text: {
      primary: PRIMARY_DARK,
    },
    border: {
      main: BORDER_GREY,
    },
    background: {
      light: BACKGROUND_LIGHT,
      dark: BACKGROUND_DARK,
      white: BACKGROUND_WHITE,
      black: BACKGROUND_BLACK,
    },
    action: {},
  },
  typography: {
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: PRIMARY_DARK,
        underline: "always",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        contained: {
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
