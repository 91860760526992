export const UOM_FIELDS = [
  {
    value: "PIECE",
    label: "pages-products-add-product-form-uom-field-piece",
  },
  {
    value: "UNIT",
    label: "pages-products-add-product-form-uom-field-unit",
  },
  {
    value: "LITER",
    label: "pages-products-add-product-form-uom-field-liter",
  },
  {
    value: "MILLILITER",
    label: "pages-products-add-product-form-uom-field-milliliter",
  },
  {
    value: "KILOGRAMM",
    label: "pages-products-add-product-form-uom-field-kilogramm",
  },
  {
    value: "DECAGRAMM",
    label: "pages-products-add-product-form-uom-field-decagramm",
  },
  {
    value: "GRAMM",
    label: "pages-products-add-product-form-uom-field-gramm",
  },
  {
    value: "BUNDLE",
    label: "pages-products-add-product-form-uom-field-bundle",
  },
];
