import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useImportedVends } from "../../hooks/useVends";
import MessageBox from "../common/MessageBox";
import ImportedVendsTable from "./ImportedVendsTable";

const ImportedVendsList = () => {
  const { t } = useTranslation();
  const { data: importedVends, error, isLoading } = useImportedVends();

  if (isLoading)
    return (
      <MessageBox>
        <CircularProgress />
      </MessageBox>
    );
  if (error) return <MessageBox>Error: {error.message}</MessageBox>;
  if (!importedVends || importedVends.length === 0)
    return <MessageBox>{t("pages-vends-no-uploads")}</MessageBox>;

  return <ImportedVendsTable data={importedVends} />;
};

export default ImportedVendsList;
