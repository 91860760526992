import { Box, Typography } from "@mui/material";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CustomIcon, { IconName } from "../common/CustomIcon";

interface SupplierInfoProps {
  icon: IconName;
  text: string;
  smDown: boolean;
}

const SupplierInfo = ({ icon, text }: SupplierInfoProps) => {
  const { smDown } = useBreakpoint();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CustomIcon size={smDown ? 15 : 20} icon={icon} />
      <Typography
        variant="body1"
        sx={{
          fontSize: smDown ? 14 : undefined,
          paddingLeft: smDown ? 0.5 : 1,
          color: "common.black",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SupplierInfo;
