import Drawer from "@mui/material/Drawer";
import { DRAWER_WIDTH } from "../../config/style/theme";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import AddSupplierCard from "./AddSupplierCard";

interface AddSupplierDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function AddSupplierDrawer({
  open,
  onClose,
}: AddSupplierDrawerProps) {
  const { smDown } = useBreakpoint();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={smDown ? "bottom" : "right"}
      PaperProps={{
        sx: {
          p: 0.5,
          height: "100%",
          width: {
            xs: "100%",
            sm: DRAWER_WIDTH,
            md: DRAWER_WIDTH,
            lg: DRAWER_WIDTH,
          },
          borderTopLeftRadius: smDown ? 0 : 16,
          borderBottomLeftRadius: 0,
        },
      }}
    >
      <AddSupplierCard onClose={onClose} />
    </Drawer>
  );
}
