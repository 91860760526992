import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/system";
import { useState } from "react";
import { ReactComponent as BioBringerSVG } from "../../assets/svg/logos/biobringer_logo_large_dark.svg";
import { useCustomNavigation } from "../../hooks/useCustomNavigation";
import HideOnScroll from "../common/HideOnScroll";
import AccountMenu from "./AccountMenu";
import MobileDrawer from "./MobileDrawer";

function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const logoRedirectActive = false;
  const { navigateToDashboard } = useCustomNavigation();

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            bgcolor: "background.white",
            borderBottom: "1px solid",
            borderColor: "border.main",
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <IconButton
                size="large"
                aria-label="open drawer"
                onClick={handleOpenDrawer}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="home"
                  onClick={navigateToDashboard}
                  disabled={!logoRedirectActive}
                >
                  <BioBringerSVG
                    style={{
                      width: "100%",
                      height: 40,
                      objectFit: "contain",
                    }}
                  />
                </IconButton>
              </Box>
              <AccountMenu />
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <MobileDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </>
  );
}

export default MobileNavbar;
