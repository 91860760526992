import axiosClient, { handleAxiosError } from "./services/axiosClient";
const SUB_PATH = "/address";

export interface AddressData {
  street: string;
  house_number: string;
  apartment_number?: string;
  floor?: string;
  city: string;
  zipcode: number;
  country: string;
  additional_information?: string;
  id: string;
}

export const addAddress = async (
  addressData: AddressData,
): Promise<AddressData> => {
  try {
    const response = await axiosClient.post<AddressData>(
      `${SUB_PATH}`,
      addressData,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};
