import { Box, FormControl, RadioGroup, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SupplierListItem } from "../../api/suppliers";
import { inboundLogisticsFields } from "../../config/forms/logisticsFormFields";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useSupplierList } from "../../hooks/useSuppliers";
import { sortByDate } from "../../utils/helpers";
import CustomInfoBox from "../common/CustomInfoBox";
import CustomSwitch from "../common/CustomSwitch";
import SupplierCardItem from "./SupplierCardItem";

const SupplierSelectElement: React.FC = () => {
  const { control, setValue, getValues } = useFormContext();
  const { data: supplierList } = useSupplierList();
  const { t } = useTranslation();
  const { smDown } = useBreakpoint();

  const sortedSupplierList = supplierList
    ? sortByDate(supplierList, "created_on_")
    : [];

  const inboundLogisticsType = useWatch({
    control,
    name: "inbound_logistics_type",
  });

  const selectedSupplierId = useWatch({
    control,
    name: "supplier_id",
  });

  const handleSupplierSelect = (supplierId: string) => {
    setValue("supplier_id", supplierId);
    setInboundLogisticsTypeToDefault(supplierId);
  };

  const setInboundLogisticsTypeToDefault = useCallback(
    (supplierId: string) => {
      const selectedSupplier = supplierList?.find(
        (supplier) => supplier.id === supplierId,
      );
      if (selectedSupplier) {
        setValue(
          "inbound_logistics_type",
          selectedSupplier.default_inbound_logistics_type,
          { shouldValidate: true },
        );
      }
    },
    [supplierList, setValue],
  );

  useEffect(() => {
    const currentSupplierId = getValues("supplier_id");
    if (currentSupplierId && supplierList) {
      setInboundLogisticsTypeToDefault(currentSupplierId);
    }
  }, [supplierList, getValues, setInboundLogisticsTypeToDefault]);

  const getInfoMessage = () => {
    if (inboundLogisticsType === inboundLogisticsFields[0].value) {
      return t("pages-products-add-supplier-select-info-delivery");
    } else {
      return t("pages-products-add-supplier-select-info-pickup");
    }
  };

  return (
    <Box
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          p: 1,
          height: smDown ? "340px" : "305px",
          border: "1px solid",
          bgcolor: "background.light",
          borderColor: "border.main",
          overflowY: "scroll",
          borderRadius: 1,
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="supplier_id"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby="supplier-select-group-label"
                sx={{ gap: 1 }}
                onChange={(_, value) => {
                  field.onChange(value);
                  setInboundLogisticsTypeToDefault(value);
                }}
              >
                {sortedSupplierList?.map((supplier: SupplierListItem) => (
                  <SupplierCardItem
                    key={supplier.id}
                    id={supplier.id}
                    name={supplier.name}
                    email={supplier.email}
                    phone={supplier.phone_number}
                    mobile={supplier.mobile_number}
                    onSelect={handleSupplierSelect}
                    isSelected={supplier.id === selectedSupplierId}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomInfoBox label={getInfoMessage()} title="hi " />
        <Controller
          name="inbound_logistics_type"
          control={control}
          defaultValue={inboundLogisticsFields[0].value}
          render={({ field }) => (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body1" fontWeight={"bold"}>
                {t(inboundLogisticsFields[0].label)}
              </Typography>
              <CustomSwitch
                checked={field.value === inboundLogisticsFields[0].value}
                onChange={(event) => {
                  field.onChange(
                    event.target.checked
                      ? inboundLogisticsFields[0].value
                      : inboundLogisticsFields[1].value,
                  );
                }}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default SupplierSelectElement;
