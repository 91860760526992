import React from "react";
import { useScrollBehavior } from "../../hooks/useScrollbehavior";

interface StickyHeaderProps {
  children: React.ReactElement;
  initialTop: number | string;
}

const StickyHeader = ({ children, initialTop }: StickyHeaderProps) => {
  const { isScrolled, isScrollingUp } = useScrollBehavior();

  const childStyle = children.props.style || {};

  const newStyle = {
    ...childStyle,
    position: "fixed",
    top: isScrolled && !isScrollingUp ? 0 : initialTop,
    transition: "top 0.15s",
    width: "100%",
    zIndex: 1000,
  };

  return React.cloneElement(children, {
    style: newStyle,
  });
};

export default StickyHeader;
