import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CustomErrorBoxProps {
  errorMessage: string;
}

const CustomErrorBox = ({ errorMessage }: CustomErrorBoxProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="button"
        sx={{ fontWeight: "bold", color: "action.active" }}
      >
        {t(errorMessage)}
      </Typography>
    </Box>
  );
};

export default CustomErrorBox;
