import { Grid, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import {
  NAVBAR_SMALL_WIDTH,
  NAVBAR_WIDE_WIDTH,
} from "../../config/style/theme";
import {
  bottomNavigationElements,
  topNavigationElements,
} from "../../config/navigation/navigationElements";
import CustomTooltip from "../common/CustomTooltip";
import LogoutButton from "./LogoutButton";
import NavigationElement from "./NavigationElement";
import TenantSelector from "./TenantSelector";

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { smDown, lgDown } = useBreakpoint();

  const desktopGridStyle = {
    width: "100%",
    height: smDown ? "100vh" : "100%",
    flexShrink: 0,
    gap: 2,
    p: 2,
    borderRadius: smDown ? 0 : 1,
    background: theme.palette.background.white,
    border: smDown ? "none" : `1px solid ${theme.palette.border.main}`,
  };

  return (
    <Box
      sx={{
        padding: smDown ? 0 : lgDown ? 1 : 1.5,
        paddingRight: 0,
        width: {
          sm: NAVBAR_SMALL_WIDTH,
          lg: NAVBAR_WIDE_WIDTH,
        },
        flexShrink: 0,
        transition: "width 0.3s ease",
        background: theme.palette.background.light,
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        direction="column"
        sx={desktopGridStyle}
      >
        <Grid
          container
          gap={2}
          sx={{
            maxWidth: 350,
          }}
        >
          <TenantSelector />
          {topNavigationElements.map((navigationElement, index) => (
            <CustomTooltip
              title={t(navigationElement.title)}
              disabled={navigationElement.disabled}
              showtooltip={lgDown ? true : false}
              key={index}
            >
              <Grid
                item
                sx={{
                  width: "100%",
                }}
              >
                <NavigationElement
                  title={t(navigationElement.title)}
                  route={navigationElement.route}
                  icon={navigationElement.icon}
                  disabled={navigationElement.disabled}
                />
              </Grid>
            </CustomTooltip>
          ))}
        </Grid>
        <Grid container gap={2} sx={{ maxWidth: 350 }}>
          {bottomNavigationElements.map((navigationElement, index) => (
            <CustomTooltip
              title={t(navigationElement.title)}
              disabled={navigationElement.disabled}
              showtooltip={lgDown ? true : false}
              key={index}
            >
              <Grid
                item
                sx={{
                  width: "100%",
                }}
              >
                <NavigationElement
                  title={t(navigationElement.title)}
                  route={navigationElement.route}
                  icon={navigationElement.icon}
                  disabled={navigationElement.disabled}
                />
              </Grid>
            </CustomTooltip>
          ))}
          <CustomTooltip
            title={t("global-logout")}
            disabled={false}
            showtooltip={lgDown ? true : false}
          >
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <LogoutButton
                title={t("global-logout")}
                icon={"logout"}
                disabled={false}
              />
            </Grid>
          </CustomTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
