import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { IconName } from "../common/CustomIcon";
import CustomListItem from "./CustomListItem";
import CustomListItemButton from "./CustomListItemButton";

interface NavigationElementProps {
  title: string;
  route: string;
  icon: IconName;
  disabled: boolean;
}

const NavigationElement: React.FC<NavigationElementProps> = ({
  title,
  route,
  icon,
  disabled,
}) => {
  const navigate = useNavigate();

  const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    startTransition(() => {
      navigate(route);
    });
  };

  return (
    <CustomListItem route={route} disabled={disabled}>
      <CustomListItemButton
        title={title}
        route={route}
        icon={icon}
        onClick={handleNavigation}
        disabled={disabled}
      />
    </CustomListItem>
  );
};

export default NavigationElement;
