import { Avatar, Box, Grid, Radio } from "@mui/material";
import React from "react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import SupplierInfo from "./SupplierInfo";

interface SupplierCardItemProps {
  id: string;
  name: string;
  email: string;
  phone?: string;
  mobile?: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

const SupplierCardItem = ({
  id,
  name,
  email,
  phone,
  mobile,
  isSelected,
  onSelect,
}: SupplierCardItemProps) => {
  const { smDown } = useBreakpoint();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onSelect(id);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: "100%",
        borderRadius: 1,
        cursor: "pointer",
        "&:hover": {
          "& .MuiGrid-root": {
            borderColor: "primary.main",
          },
        },
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: "background.white",
          height: 80,
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          border: isSelected ? "2px solid" : "1px solid",
          borderRadius: 1,
          borderColor: isSelected ? "primary.main" : "border.main",
          transition: "border-color 0.3s",
        }}
      >
        <Grid
          item
          xs={smDown ? 2 : 1.5}
          sx={{
            display: "grid",
            placeContent: "center",
          }}
        >
          <Avatar
            alt={name}
            sx={{
              borderRadius: smDown ? 0.5 : 1,
              width: smDown ? 40 : 56,
              height: smDown ? 40 : 56,
            }}
          />
        </Grid>
        <Grid
          item
          xs={smDown ? 8 : 9}
          sx={{
            px: smDown ? 1 : 2,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            borderLeft: isSelected ? "2px solid" : "1px solid",
            borderRadius: 0,
            borderColor: isSelected ? "primary.main" : "border.main",
            transition: "border-color 0.3s",
          }}
        >
          <SupplierInfo icon="supplier" text={name} smDown={smDown} />
          <SupplierInfo icon="email" text={email} smDown={smDown} />
          <SupplierInfo
            icon="phone"
            text={phone ?? mobile ?? "-"}
            smDown={smDown}
          />
        </Grid>
        <Grid
          item
          xs={smDown ? 2 : 1.5}
          sx={{
            p: 0,
            height: "100%",
            display: "grid",
            placeContent: "center",
            borderLeft: isSelected ? "2px solid" : "1px solid",
            borderRadius: 0,
            borderColor: isSelected ? "primary.main" : "border.main",
            transition: "border-color 0.3s",
          }}
        >
          <Radio
            checked={isSelected}
            onClick={(e) => e.stopPropagation()}
            sx={{ pointerEvents: "none" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupplierCardItem;
