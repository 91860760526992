import axios, { AxiosResponse } from "axios";
import axiosClient, { handleAxiosError } from "./services/axiosClient";

enum VendsPaths {
  UPLOAD_VENDS_SUB_PATH = "/import/vends-csv",
  IMPORTED_VENDS_SUB_PATH = "/imported-vends",
}

export interface CSVUploadResponse {
  detected_duplicates: string[];
  message: string;
  success: boolean;
}

export interface CSVUploadError {
  errors: Record<string, string[]>;
  message: string;
}

export const uploadVendCSV = async (file: File): Promise<CSVUploadResponse> => {
  if (!file.name.endsWith(".csv")) {
    throw new Error("pages-vends-invalid-file-type");
  }
  try {
    const formData = new FormData();
    formData.append("vends", file, file.name);

    const response: AxiosResponse<CSVUploadResponse> = await axiosClient.post(
      VendsPaths.UPLOAD_VENDS_SUB_PATH,
      formData,
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
    }
    throw handleAxiosError(VendsPaths.UPLOAD_VENDS_SUB_PATH, error);
  }
};

export interface ImportedVendsResponse {
  category: string;
  code: string;
  column: string;
  created_on_: string;
  id: string;
  machine: string;
  machine_mode: string;
  model_number: string;
  payment: string;
  product_code: string;
  product_description: string;
  quantity: number;
  recipe: string;
  tax_rate: number;
  timestamp: string;
  type: string;
  updated_on_: string;
  value: number;
}
export interface ImportedVendsTableProps {
  data: ImportedVendsResponse[];
}

export const getImportedVends = async (): Promise<ImportedVendsResponse[]> => {
  try {
    const response: AxiosResponse<ImportedVendsResponse[]> =
      await axiosClient.get(VendsPaths.IMPORTED_VENDS_SUB_PATH);
    return response.data;
  } catch (e) {
    throw handleAxiosError(VendsPaths.IMPORTED_VENDS_SUB_PATH, e);
  }
};
