import axiosClient, { handleAxiosError } from "./services/axiosClient";
import { AxiosResponse } from "axios";

const SUB_PATH_PRODUCT = "/product";

export interface ProductListItem {
  column: string;
  description: string;
  id: string;
  sku: string;
  uom: string;
}

export interface AddProductFormData {
  id?: string;
  description?: string;
  inbound_logistics_type?: string;
  sku: string;
  supplier_id: string;
  uom: string;
}

export const getProductList: () => Promise<ProductListItem[]> = async () => {
  try {
    const response: AxiosResponse<ProductListItem[]> = await axiosClient.get(
      `${SUB_PATH_PRODUCT}`,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH_PRODUCT}`, e);
  }
};

export const addProduct = async (
  productData: AddProductFormData,
): Promise<AddProductFormData> => {
  const newProductData: AddProductFormData = {
    ...productData,
  };
  try {
    const response = await axiosClient.post<AddProductFormData>(
      `${SUB_PATH_PRODUCT}`,
      newProductData,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH_PRODUCT}`, e);
  }
};

export const deleteProduct = async (
  productId: string,
): Promise<ProductListItem[]> => {
  try {
    const response: AxiosResponse<ProductListItem[]> = await axiosClient.delete(
      `${SUB_PATH_PRODUCT}/${productId}`,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH_PRODUCT}`, e);
  }
};
