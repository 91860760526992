import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { User } from "../../../api/userManagement";
import CustomActionMenu from "../../common/CustomActionMenu";
import UserRoleChip from "./UserRoleChip";

interface UserListItemProps {
  user: User;
  onDelete: (user: User) => void;
}

const UserListItem: React.FC<UserListItemProps> = ({ user, onDelete }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(user);
    handleMenuClose();
  };

  const validRoles = user.roles.filter((role) => role.trim() !== "");
  const sortedValidRoles = validRoles
    .slice()
    .sort((a, b) => a.localeCompare(b));

  return (
    <Paper
      elevation={0}
      sx={{
        px: 2,
        height: 66,
        mb: 1.5,
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        border: "1px solid",
        borderColor: theme.palette.border.main,
        "&:hover": {
          borderColor: "primary.lighter",
        },
      }}
    >
      <Avatar
        sx={{
          width: 40,
          height: 40,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          mr: 1.5,
        }}
      >
        {user.username.charAt(0).toUpperCase()}
      </Avatar>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography variant="subtitle2" noWrap>
          {user.username}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {user.email}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          ml: 1,
        }}
      >
        {validRoles.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              overflow: "hidden",
            }}
          >
            {sortedValidRoles.map((role) => (
              <UserRoleChip role={role} key={role} />
            ))}
          </Box>
        )}
        <IconButton
          aria-label="user-actions-menu-icon-button"
          onClick={handleMenuOpen}
          sx={{ ml: 1 }}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
      <CustomActionMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onDelete={handleDelete}
      />
    </Paper>
  );
};

export default UserListItem;
