import axiosClient, { handleAxiosError } from "./services/axiosClient";
import { AxiosResponse } from "axios";

const SUB_PATH = "/supplier";
const SUB_PATH_SUPPLIER_ADDRESS = "/supplier-address";

export interface SupplierListItem {
  created_on_: string;
  default_inbound_logistics_type: string;
  default_logistics_provider_id: string;
  default_pickup_address_id: string;
  email: string;
  greeting: string;
  id: string;
  mobile_number: string;
  name: string;
  phone_number: string;
  updated_on: string;
}

export interface SupplierFormData {
  id: string;
  default_inbound_logistics_type: string;
  default_logistics_provider_id: string | null;
  default_pickup_address_id: string;
  email: string;
  greeting: string;
  mobile_number: string;
  name: string;
  phone_number: string;
}

export interface SupplierAddressData {
  address: string;
  supplier: string;
}

export const getSupplierList = async (): Promise<SupplierListItem[]> => {
  try {
    const response: AxiosResponse<SupplierListItem[]> = await axiosClient.get(
      `${SUB_PATH}`,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};

export const getSupplierById = async (
  id: string,
): Promise<SupplierFormData> => {
  try {
    const response: AxiosResponse<SupplierFormData> = await axiosClient.get(
      `${SUB_PATH}/${id}`,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}/${id}`, e);
  }
};

export const addSupplier = async (
  supplierData: SupplierFormData,
): Promise<SupplierFormData> => {
  const newSupplierData: SupplierFormData = {
    ...supplierData,
  };
  try {
    const response = await axiosClient.post<SupplierFormData>(
      `${SUB_PATH}`,
      newSupplierData,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};

export const addSupplierAddress = async (
  supplierAddressData: SupplierAddressData,
): Promise<SupplierAddressData> => {
  const newSupplierAddress: SupplierAddressData = {
    ...supplierAddressData,
  };
  try {
    const response = await axiosClient.post<SupplierAddressData>(
      `${SUB_PATH_SUPPLIER_ADDRESS}`,
      newSupplierAddress,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH_SUPPLIER_ADDRESS}`, e);
  }
};

// export const updateSupplier = async (
//   id: string,
//   supplierData: Partial<SupplierFormData>,
// ): Promise<SupplierDataResponse> => {
//   try {
//     const response = await axiosClient.patch<SupplierDataResponse>(
//       `${SUB_PATH}/${id}`,
//       {
//         ...supplierData,
//       },
//     );
//     return response.data;
//   } catch (e) {
//     throw handleAxiosError(`${SUB_PATH}/${id}`, e);
//   }
// };

// export const deleteSupplier = async (id: string): Promise<void> => {
//   try {
//     await axiosClient.delete(`${SUB_PATH}/${id}`);
//   } catch (e) {
//     throw handleAxiosError(`${SUB_PATH}/${id}`, e);
//   }
// };
