import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { SortableKeys, useTranslatedHeadCells } from "../../utils/helpers";

interface ImportedVendsTableHeaderProps {
  orderBy: SortableKeys;
  order: "asc" | "desc";
  sortableFields: SortableKeys[];
  onRequestSort: (property: SortableKeys) => void;
}

const ImportedVendsTableHeader = ({
  orderBy,
  order,
  sortableFields,
  onRequestSort,
}: ImportedVendsTableHeaderProps): JSX.Element => {
  const headCells = useTranslatedHeadCells();
  const theme = useTheme();

  return (
    <TableHead
      sx={{
        position: "relative",
        zIndex: 0,
        height: 50,
      }}
    >
      <TableRow
        sx={{
          "& td, & th": {
            borderColor: theme.palette.border.main,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              px: 2,
              py: 1,
              fontWeight: "bold",
              ...(headCell.align === "right" && {
                textAlign: "right",
              }),
            }}
            key={headCell.key}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {sortableFields.includes(headCell.key) ? (
              <TableSortLabel
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : "asc"}
                onClick={() => onRequestSort(headCell.key)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ImportedVendsTableHeader;
