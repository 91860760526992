import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useTheme } from "@mui/material";

interface CustomButtonProps {
  customButtonId: string;
  customButtonText?: string;
  customButtonFunc?: () => void;
  customButtonPrimary?: boolean;
  customButtonIcon?: React.ReactNode;
  customButtonDisabled?: boolean;
}
//TODO: add CustomTooltip to button on mobile view!
const CustomButton: React.FC<CustomButtonProps> = ({
  customButtonId,
  customButtonText,
  customButtonFunc,
  customButtonPrimary,
  customButtonIcon,
  customButtonDisabled = false,
}) => {
  const { t } = useTranslation();
  const { mdDown } = useBreakpoint();
  const theme = useTheme();
  return (
    <Button
      disabled={customButtonDisabled}
      key={customButtonId}
      onClick={customButtonFunc}
      sx={{
        textTransform: "capitalize",
        fontWeight: "bold",
        minWidth: mdDown ? "40px" : "auto",
        width: mdDown ? "40px" : "auto",
        height: mdDown ? "40px" : "auto",
        padding: mdDown ? 1 : theme.spacing(1, 2),
        justifyContent: mdDown ? "center" : "flex-start",
        borderRadius: 1,
      }}
      size="small"
      variant={customButtonPrimary ? "contained" : "outlined"}
    >
      {customButtonIcon && (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: mdDown ? 0 : 1,
          }}
        >
          {customButtonIcon}
        </span>
      )}
      {!mdDown && customButtonText && t(customButtonText)}
    </Button>
  );
};

export default CustomButton;
