import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductListItem } from "../../api/product";
import biobringerLogoSmall from "../../assets/png/logos/biobringer_logo_small_dark.png";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CustomActionMenu from "../common/CustomActionMenu";
import CustomTooltip from "../common/CustomTooltip";

interface ProductListRowItemProps {
  product: ProductListItem;
  onDelete: (product: ProductListItem) => void;
  actionsDisabled: boolean;
}
const ProductListRowItem: React.FC<ProductListRowItemProps> = ({
  product,
  onDelete,
  actionsDisabled,
}) => {
  const { t } = useTranslation();
  const { lgDown } = useBreakpoint();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(product);
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <TableRow>
      <TableCell width={100} align="center">
        {product.sku}
      </TableCell>
      <TableCell width="100%" align="left" sx={{ py: 0, height: 56 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              bgcolor: "background.light",
              mr: 2,
              borderRadius: 1,
              p: 1,
            }}
            src={biobringerLogoSmall}
            alt={product.description.trim()}
          />
          <Typography variant="body2">{product.description.trim()}</Typography>
        </Box>
      </TableCell>
      <TableCell width={50} align="center">
        <CustomTooltip
          disabled={actionsDisabled}
          title="global-actions"
          showtooltip={lgDown ? true : false}
        >
          <>
            <IconButton
              aria-label="product-actions-menu-icon-button"
              onClick={handleMenuOpen}
              sx={{ ml: 1 }}
            >
              <MoreVertIcon />
            </IconButton>
            <CustomActionMenu
              anchorEl={anchorEl}
              isOpen={Boolean(anchorEl)}
              onClose={handleMenuClose}
              onDelete={handleDeleteClick}
            />
          </>
        </CustomTooltip>
      </TableCell>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box>
            <Typography>
              {t("pages-products-delete-product", {
                product: product.description,
              })}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleDeleteCancel} variant="outlined">
            {t("pages-settings-cancel-delete")}
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
          >
            {t("pages-settings-delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default ProductListRowItem;
