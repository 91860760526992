import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircle, HighlightOff } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { AddressData } from "../../api/address";
import { SupplierAddressData, SupplierFormData } from "../../api/suppliers";
import { useDefaultLogisticProvider } from "../../hooks/useLogisticProvider";
import { useSupplierMutations } from "../../hooks/useSupplierMutations";
import { useSupplierList } from "../../hooks/useSuppliers";
import createAddSupplierSchema from "../../utils/validation/AddSupplierValidation";
import CustomCard from "../common/CustomCard";
import AddSupplierForm from "./AddSupplierForm";

interface AddSupplierProps {
  onClose: () => void;
}

const AddSupplierCard = ({ onClose }: AddSupplierProps) => {
  const { t } = useTranslation();
  const { refetch } = useSupplierList();
  const AddSupplierSchema = createAddSupplierSchema(t);
  const {
    addSupplierAddressMutation,
    addSupplierMutation,
    addAddressMutation,
  } = useSupplierMutations();
  const { data: defaultLogisticsProvider } = useDefaultLogisticProvider();

  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(AddSupplierSchema),
    defaultValues: {
      name: "",
      greeting: "",
      email: "",
      phone_number: "",
      mobile_number: "",
      default_inbound_logistics_type: "COLLECT",
      street: "",
      house_number: "",
      apartment_number: "",
      floor: "",
      city: "",
      zipcode: "",
      country: "",
      additional_information: "",
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;
  const onSubmit = async (data: any) => {
    try {
      const addressData: AddressData = {
        street: data.street,
        house_number: data.house_number,
        apartment_number: data.apartment_number,
        floor: data.floor,
        city: data.city,
        zipcode: data.zipcode,
        country: data.country,
        additional_information: data.additional_information || "",
        id: uuidv4(),
      };
      const addressResponse = await addAddressMutation.mutateAsync(addressData);

      const supplierData: SupplierFormData = {
        id: uuidv4(),
        default_inbound_logistics_type: data.default_inbound_logistics_type,
        default_logistics_provider_id: defaultLogisticsProvider?.id || null,
        default_pickup_address_id: addressResponse.id,
        email: data.email,
        greeting: data.greeting,
        mobile_number: data.mobile_number,
        name: data.name,
        phone_number: data.phone_number,
      };
      const supplierResponse =
        await addSupplierMutation.mutateAsync(supplierData);

      const supplierAddressData: SupplierAddressData = {
        address: addressData.id,
        supplier: supplierResponse.id,
      };

      await addSupplierAddressMutation.mutateAsync(supplierAddressData);
      refetch();
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      enqueueSnackbar(t("error-adding-supplier"), { variant: "error" });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <CustomCard
        cardId="add-supplier-form"
        cardTitle="pages-products-add-supplier-form-title"
        cardHasButton={true}
        cardButtonTitle="global-cancel"
        cardButtonIcon={<HighlightOff />}
        cardButtonPrimary={false}
        cardButtonFunc={onClose}
        cardHasSecondaryButton={true}
        cardSecondaryButtonTitle="global-save"
        cardSecondaryButtonIcon={<CheckCircle />}
        cardSecondaryButtonPrimary={true}
        cardSecondaryButtonFunc={handleSubmit(onSubmit)}
        cardSecondaryButtonDisabled={!isValid}
        hasNoBorder={true}
        headerHasBorder={true}
        cardContent={<AddSupplierForm />}
      />
    </FormProvider>
  );
};

export default AddSupplierCard;
