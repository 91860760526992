import { Box } from "@mui/system";
import React from "react";

interface CustomButtonBoxProps {
  children: React.ReactNode;
}

const CustomButtonBox = ({ children }: CustomButtonBoxProps) => {
  return <Box sx={{ display: "flex", gap: 1 }}>{children}</Box>;
};

export default CustomButtonBox;
