import axiosClient, { handleAxiosError } from "./services/axiosClient";

const SUB_PATH_DEFAULT_LOGISTICS_PROVIDER = `/logistics-provider?filter=%7B"name"%3A%20"BioBringer%20Logistik"%7D`;

export interface LogisticsProviderItem {
  created_on: string;
  email: string;
  greeting: string;
  id: string;
  mobile_number: string;
  name: string;
  phone_number: string;
  updated_on: string;
}

export const getDefaultLogisticsProvider =
  async (): Promise<LogisticsProviderItem> => {
    try {
      const response = await axiosClient.get(
        `${SUB_PATH_DEFAULT_LOGISTICS_PROVIDER}`,
      );
      return response.data[0];
    } catch (e) {
      throw handleAxiosError(`${SUB_PATH_DEFAULT_LOGISTICS_PROVIDER}`, e);
    }
  };
