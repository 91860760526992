import { CheckCircle, HighlightOff } from "@mui/icons-material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import ContentBox from "../../../components/common/ContentBox";
import CustomButton from "../../../components/common/CustomButton";
import CustomButtonBox from "../../../components/common/CustomButtonBox";
import AddProductForm from "../../../components/products/AddProductForm";
import { useCustomNavigation } from "../../../hooks/useCustomNavigation";
import { useDefaultLogisticProvider } from "../../../hooks/useLogisticProvider";
import { AddProductFormData } from "../../../api/product";
import { useProductMutations } from "../../../hooks/useProductMutations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { createAddProductSchema } from "../../../utils/validation/AddProductValidation";

const AddProductPage: React.FC = () => {
  const { navigateToProducts } = useCustomNavigation();
  const { data: defaultLogisticsProvider } = useDefaultLogisticProvider();
  const { addProductMutation } = useProductMutations();
  const { t } = useTranslation();
  const AddProductSchema = createAddProductSchema(t);

  const formMethods = useForm<AddProductFormData>({
    mode: "onChange",
    resolver: yupResolver(AddProductSchema),
    defaultValues: {
      id: "",
      description: "",
      inbound_logistics_type: "",
      sku: "",
      uom: "PIECE",
      supplier_id: "",
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmit = async (data: AddProductFormData) => {
    try {
      const addProductFormData = {
        ...data,
        id: uuidv4(),
        logistics_provider_id: defaultLogisticsProvider?.id || null,
      };
      await addProductMutation.mutateAsync(addProductFormData);
      navigateToProducts();
    } catch (e) {
      console.error("Error submitting form:", e);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <ContentBox
        pageId="add-product-page"
        pageTitle={"pages-add-product-title"}
        pageHeaderHasActions={true}
        pageHeaderAction={
          <CustomButtonBox>
            <CustomButton
              customButtonId="close-product-button"
              customButtonText="global-close"
              customButtonPrimary={false}
              customButtonIcon={<HighlightOff />}
              customButtonFunc={navigateToProducts}
            />
            <CustomButton
              customButtonId="save-product-button"
              customButtonText="global-save"
              customButtonPrimary={true}
              customButtonIcon={<CheckCircle />}
              customButtonDisabled={!isValid}
              customButtonFunc={handleSubmit(onSubmit)}
            />
          </CustomButtonBox>
        }
        pageContent={<AddProductForm />}
      />
    </FormProvider>
  );
};

export default AddProductPage;
