import DeleteIcon from "@mui/icons-material/Delete";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface UserActionMenuProps {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const CustomActionMenu: React.FC<UserActionMenuProps> = ({
  anchorEl,
  isOpen,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText sx={{ color: theme.palette.error.main }}>
          {t("Delete")}
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default CustomActionMenu;
