import { useEffect, useState } from "react";

interface ScrollBehavior {
  isScrolled: boolean;
  isScrollingUp: boolean;
}

export const useScrollBehavior = (threshold = 75): ScrollBehavior => {
  const [scrollBehavior, setScrollBehavior] = useState<ScrollBehavior>({
    isScrolled: false,
    isScrollingUp: false,
  });

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollBehavior = () => {
      const scrollY = window.pageYOffset;
      const isScrolled = scrollY > threshold;
      const isScrollingUp = scrollY < lastScrollY;

      setScrollBehavior({ isScrolled, isScrollingUp });
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollBehavior, { passive: true });
    return () => window.removeEventListener("scroll", updateScrollBehavior);
  }, [threshold]);

  return scrollBehavior;
};
