import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { addAddress, AddressData } from "../api/address";
import {
  addSupplier,
  addSupplierAddress,
  SupplierAddressData,
  SupplierFormData,
} from "../api/suppliers";
import { useSupplierList } from "./useSuppliers";

export const useSupplierMutations = () => {
  const { t } = useTranslation();
  const { refetch } = useSupplierList();

  const addAddressMutation = useMutation({
    mutationFn: (data: AddressData) => addAddress(data),
    onError: (error: any) => {
      let errorMessage = t("pages-products-add-supplier-address-error");
      if (error.response?.data?.message) {
        errorMessage += `: ${error.response.data.message}`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });

  const addSupplierMutation = useMutation({
    mutationFn: (data: SupplierFormData) => addSupplier(data),
    onSuccess: () => {
      enqueueSnackbar(t("pages-products-add-supplier-success"), {
        variant: "success",
      });
      refetch();
    },
    onError: (error: any) => {
      let errorMessage = t("pages-settings-supplier-creation-error");
      if (error.response?.data?.message) {
        errorMessage += `: ${error.response.data.message}`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });

  const addSupplierAddressMutation = useMutation({
    mutationFn: (data: SupplierAddressData) => addSupplierAddress(data),
    onError: (error: any) => {
      let errorMessage = t("pages-products-add-supplier-address-error");
      if (error.response?.data?.message) {
        errorMessage += `: ${error.response.data.message}`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });

  return {
    addAddressMutation,
    addSupplierMutation,
    addSupplierAddressMutation,
  };
};
