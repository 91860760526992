import { ListItem, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { isActive } from "../../utils/helpers";

interface CustomListItemProps {
  children: React.ReactNode;
  route: string;
  disabled: boolean;
}

const CustomListItem = ({ children, route, disabled }: CustomListItemProps) => {
  const theme = useTheme();
  const { lgDown, lgUp, smDown } = useBreakpoint();
  const location = useLocation();
  const active = isActive(route, location);
  const showFullNav = lgUp || smDown;

  return (
    <ListItem
      disablePadding
      sx={{
        marginX: "auto",
        height: lgDown ? "48px" : "50px",
        width: showFullNav ? "100%" : "48px",
        padding: 0,
        borderRadius: 1,
        border: `1px solid ${theme.palette.border.main}`,
        backgroundColor: active ? theme.palette.primary.main : "transparent",
        color: active
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary,
        "&:hover": {
          border: `1px solid ${disabled ? theme.palette.border.main : theme.palette.primary.main}`,
        },
        "&:hover:disabled": {
          border: `1px solid ${theme.palette.border.main}`,
        },
      }}
      disabled={disabled}
    >
      {children}
    </ListItem>
  );
};

export default CustomListItem;
