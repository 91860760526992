import { ListItem, useTheme } from "@mui/material";
import React from "react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CustomIcon from "../common/CustomIcon";
import CustomTooltip from "../common/CustomTooltip";

const TenantSelector: React.FC = () => {
  const { lgDown } = useBreakpoint();
  const theme = useTheme();
  const listItemStyle = {
    height: "50px",
    width: "100%",
    borderRadius: 1,
    backgroundColor: "transparent",
    color: theme.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    padding: 1,
    marginBottom: 2,
  };

  return (
    <CustomTooltip
      title={"Biobringer"}
      disabled={false}
      showtooltip={lgDown ? true : false}
    >
      <ListItem disablePadding sx={listItemStyle}>
        <>
          {lgDown ? (
            <CustomIcon icon="biobringerLogoSmall" />
          ) : (
            <>
              <CustomIcon icon="biobringerLogo" size={100} />
            </>
          )}
        </>
      </ListItem>
    </CustomTooltip>
  );
};

export default TenantSelector;
