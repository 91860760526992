import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Navbar from "../components/navigation/Navbar";
import MobileNavbar from "../components/navigation/MobileNavbar";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { DESKTOP_CONTENT_CONTAINER_MAX_WIDTH } from "../config/style/theme";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export function DefaultInAppLayout({ children }: DefaultLayoutProps) {
  const { lgDown, smDown } = useBreakpoint();
  const theme = useTheme();

  const desktopContentWrapperStyle = {
    flexGrow: 1,
    padding: lgDown ? 1 : 1.5,
    paddingBottom: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    background: theme.palette.background.light,
  };
  const desktopContentContainerStyle = {
    margin: "0 auto",
    maxWidth: DESKTOP_CONTENT_CONTAINER_MAX_WIDTH,
    width: "100%",
    overflowY: "hidden",
    flexGrow: 1,
    height: `calc(100vh - ${lgDown ? 1 : 1.5})`,
    // borderTopLeftRadius: theme.shape.borderRadius,
    // borderTopRightRadius: theme.shape.borderRadius,
  };

  const mobileContentWrapperStyle = {
    flexGrow: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.white,
  };

  const renderDesktopContent = () => (
    <>
      <Navbar />
      <Box sx={desktopContentWrapperStyle}>
        <Box sx={desktopContentContainerStyle}>{children}</Box>
      </Box>
    </>
  );

  const renderMobileContent = () => (
    <>
      <MobileNavbar />
      <Box sx={mobileContentWrapperStyle}>
        <Box>{children}</Box>
      </Box>
    </>
  );

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: smDown ? "column" : "row",
        height: smDown ? "auto" : "100dvh",
        overflow: "auto",
      }}
    >
      {smDown ? renderMobileContent() : renderDesktopContent()}
    </Box>
  );
}
