import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useBreakpoint } from "../hooks/useBreakpoint";

interface DefaultPublicLayoutProps {
  children: React.ReactNode;
}

export function DefaultPublicLayout(props: DefaultPublicLayoutProps) {
  const { smDown } = useBreakpoint();
  const theme = useTheme();

  const publicLayoutStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: smDown
      ? theme.palette.background.white
      : theme.palette.background.light,
  };
  return (
    <>
      <Box component={"main"} sx={publicLayoutStyle}>
        {props.children}
      </Box>
    </>
  );
}
