import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { deleteUser, User } from "../../../api/userManagement";

interface DeleteUserProps {
  open: boolean;
  user: User;
  handleClose: () => void;
  onDeleteSuccess: () => void;
}

export default function DeleteUser({
  open,
  user,
  handleClose,
  onDeleteSuccess,
}: DeleteUserProps) {
  const { t } = useTranslation();

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      onDeleteSuccess();
      handleClose();
    },
  });

  const handleDeleteUser = () => {
    deleteUserMutation.mutate(user.id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="delete-user-dialog-title"
    >
      <DialogContent>
        <Box>
          <Typography>
            {t("pages-settings-delete-user", { user: user.username })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={handleClose} variant="outlined">
          {t("pages-settings-cancel-delete")}
        </Button>
        <Button onClick={handleDeleteUser} color="primary" variant="contained">
          {t("pages-settings-delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
