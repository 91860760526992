import { HighlightOff } from "@mui/icons-material";
import ContentBox from "../../../components/common/ContentBox";
import CustomButton from "../../../components/common/CustomButton";
import CustomButtonBox from "../../../components/common/CustomButtonBox";
import VendsUpload from "../../../components/vends/VendsUpload";
import { useCustomNavigation } from "../../../hooks/useCustomNavigation";

const VendsUploadPage = () => {
  const { navigateToVends } = useCustomNavigation();

  return (
    <ContentBox
      pageId="vends-upload-page"
      pageTitle={"pages-vends-upload-title"}
      pageHeaderHasActions={true}
      pageHeaderAction={
        <CustomButtonBox>
          <CustomButton
            customButtonId="add-vends-button"
            customButtonText="global-close"
            customButtonPrimary={false}
            customButtonIcon={<HighlightOff />}
            customButtonFunc={navigateToVends}
          />
        </CustomButtonBox>
      }
      pageContent={<VendsUpload />}
    />
  );
};

export default VendsUploadPage;
