import { lazy } from "react";
import { path } from "./utils/routesUtil";
import ProductsPage from "../pages/inapp/products/ProductsPage";
import IndexPage from "../pages/IndexPage";
import LoginPage from "../pages/public/LoginPage";
import VendsPage from "../pages/inapp/vends/VendsPage";
import RefillSuccessPage from "../pages/inapp/RefillSucessPage";
import CSVUploadPage from "../pages/inapp/vends/VendsUploadPage";
import SettingsPage from "../pages/inapp/SettingsPage";
import AddProductPage from "../pages/inapp/products/AddProductPage";
const RefillPage = lazy(() => import("../pages/inapp/RefillPage"));
const AddUserPage = lazy(() => import("../pages/inapp/settings/AddUserPage"));
const ProfilePage = lazy(() => import("../pages/inapp/ProfilePage"));

const PATH_ROOT = "/" as const;

// ======= PUBLIC =======
const PATH_LOGIN = path(PATH_ROOT, "login");

// ======= IN APP =======
const PATH_REFILL = path(PATH_ROOT, "refill");
const PATH_REFILL_SUCCESS = path(PATH_REFILL, "/success");

const PATH_VENDS = path(PATH_ROOT, "vends");
const PATH_CSV_UPLOAD = path(PATH_VENDS, "/upload");

const PATH_SETTINGS = path(PATH_ROOT, "settings");
const PATH_ADD_USER = path(PATH_SETTINGS, "/add-user");

const PATH_PROFILE = path(PATH_ROOT, "profile");

const PATH_PRODUCTS = path(PATH_ROOT, "products");
const PATH_ADD_PRODUCT = path(PATH_PRODUCTS, "/add-product");

export const ROUTES_CONFIG = {
  APP: {
    path: PATH_ROOT,
    component: IndexPage,
    routes: {
      LOGIN: {
        path: PATH_LOGIN,
        component: LoginPage,
      },
      REFILL: {
        path: PATH_REFILL,
        isSecure: true,
        component: RefillPage,
        routes: {
          SUCCESS: {
            path: PATH_REFILL_SUCCESS,
            isSecure: true,
            component: RefillSuccessPage,
          },
        },
      },
      VENDS: {
        path: PATH_VENDS,
        isSecure: true,
        component: VendsPage,
        routes: {
          CSV_UPLOAD: {
            path: PATH_CSV_UPLOAD,
            isSecure: true,
            component: CSVUploadPage,
          },
        },
      },
      PRODUCTS: {
        path: PATH_PRODUCTS,
        isSecure: true,
        component: ProductsPage,
        routes: {
          ADD_PRODUCT: {
            path: PATH_ADD_PRODUCT,
            isSecure: true,
            component: AddProductPage,
          },
        },
      },
      SETTINGS: {
        path: PATH_SETTINGS,
        isSecure: true,
        component: SettingsPage,
        routes: {
          ADD_USER: {
            path: PATH_ADD_USER,
            isSecure: true,
            component: AddUserPage,
          },
        },
      },
      PROFILE: {
        path: PATH_PROFILE,
        isSecure: true,
        component: ProfilePage,
      },
    },
  },
};
