import { Control, Controller } from "react-hook-form";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CustomSelectProps {
  name: string;
  control: Control<any>;
  label: string;
  selectFields: any;
  tooltip?: string;
  required: boolean;
}

const CustomSelect = ({
  name,
  control,
  label,
  selectFields,
  tooltip = "",
  required,
}: CustomSelectProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            id={name}
            select
            fullWidth
            label={required ? `${label} *` : label}
            error={!!error}
            helperText={error?.message || ""}
            SelectProps={{
              native: true,
            }}
          >
            {selectFields.map((field: any) => (
              <option key={field.value} value={field.value}>
                {t(field.label)}
              </option>
            ))}
          </TextField>
          <Typography pl={1.75} variant="caption">
            {tooltip}
          </Typography>
        </>
      )}
    />
  );
};

export default CustomSelect;
