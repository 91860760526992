import { TableFooter, TablePagination, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CustomTableFooterProps {
  count: number;
  rowsPerPage: number;
  page: any;
  rowsPerPageOptions?: number[];
  labelRowsPerPage?: string;
  colSpan: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTableFooter = ({
  count,
  rowsPerPage,
  page,
  rowsPerPageOptions = [5, 10, 25],
  labelRowsPerPage = "global-rows-per-page",
  colSpan,
  onPageChange,
  onRowsPerPageChange,
}: CustomTableFooterProps) => {
  const { t } = useTranslation();
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ border: "none" }}
          labelRowsPerPage={t(labelRowsPerPage)}
        />
      </TableRow>
    </TableFooter>
  );
};

export default CustomTableFooter;
