import React from "react";
import CustomCard from "../common/CustomCard";
import GeneralProductDataForm from "./GeneralProductDataForm";

const GeneralProductDataCard: React.FC = () => {
  return (
    <CustomCard
      cardId="add-product-card"
      cardTitle="pages-products-add-product-general-card-title"
      cardHasButton={false}
      hasNoBorder={false}
      cardContent={<GeneralProductDataForm />}
    />
  );
};

export default GeneralProductDataCard;
