import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSupplierList } from "../../hooks/useSuppliers";
import AddSupplierDrawer from "../supplier/AddSupplierDrawer";
import SupplierSelectCard from "../supplier/SupplierSelectCard";
import GeneralProductDataCard from "./GeneralProductDataCard";

const AddProductForm: React.FC = () => {
  const { refetch } = useSupplierList();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    refetch();
  };

  return (
    <Grid
      container
      sx={{
        gap: 3,
        mx: "auto",
      }}
    >
      <Grid item xs={12}>
        <GeneralProductDataCard />
      </Grid>
      <Grid item xs={12}>
        <SupplierSelectCard handleAddSupplier={handleOpenDrawer} />
      </Grid>
      <AddSupplierDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </Grid>
  );
};

export default AddProductForm;
