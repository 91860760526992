import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { ImportedVendsResponse } from "../../api/vends";
import { formatValue, formatDate } from "../../utils/helpers";

const ImportedVendsRowElement: React.FC<ImportedVendsResponse> = (props) => {
  const tableCells = [
    {
      key: "vends_table_cell_date",
      value: formatDate(props.timestamp),
      symbol: "",
      align: "left" as const,
    },
    {
      key: "vends_table_cell_product_description",
      value: props.product_description,
      symbol: "",
      align: "left" as const,
    },
    {
      key: "vends_table_cell_quantity",
      value: formatValue(props.quantity),
      symbol: "",
      align: "right" as const,
    },
    {
      key: "vends_table_cell_value",
      value: formatValue(props.value),
      symbol: "€",
      align: "right" as const,
    },
    {
      key: "vends_table_cell_payment",
      value: props.payment,
      symbol: "",
      align: "left" as const,
    },
    {
      key: "vends_table_cell_machine",
      value: props.machine,
      symbol: "",
      align: "left" as const,
    },
  ];

  return (
    <TableRow
      sx={{
        "& td, & th": {
          borderColor: (theme) => theme.palette.border.main,
        },
      }}
    >
      {tableCells.map((cell) => (
        <TableCell
          key={cell.key}
          align={cell.align}
          sx={{
            ...(cell.align === "right" && {
              fontVariantNumeric: "tabular-nums",
            }),
            py: 0,
            height: 56,
          }}
        >
          {cell.value}
          {cell.symbol}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ImportedVendsRowElement;
