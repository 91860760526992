import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ContentBox from "../../../components/common/ContentBox";
import CustomButton from "../../../components/common/CustomButton";
import CustomButtonBox from "../../../components/common/CustomButtonBox";
import ImportedVendsList from "../../../components/vends/ImportedVendsList";
import { useCustomNavigation } from "../../../hooks/useCustomNavigation";

export default function VendsPage() {
  let pageTitle: string = "pages-vends-title";
  const { navigateToCSVUpload } = useCustomNavigation();

  return (
    <ContentBox
      pageId="vends-page"
      pageTitle={pageTitle}
      pageHeaderHasActions={true}
      pageHeaderAction={
        <CustomButtonBox>
          <CustomButton
            customButtonId="add-vends-button"
            customButtonText="pages-vends-upload-new"
            customButtonPrimary={true}
            customButtonIcon={<AddCircleOutlineOutlinedIcon />}
            customButtonFunc={navigateToCSVUpload}
          />
        </CustomButtonBox>
      }
      pageContent={<ImportedVendsList />}
    />
  );
}
