import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CSVUploadError,
  CSVUploadResponse,
  uploadVendCSV,
} from "../../api/vends";
import { useImportedVends } from "../../hooks/useVends";
import { CSV_FILE_EXTENSION, CSV_MIME_TYPE } from "../../utils/constants";
import DuplicateVendsAccordion from "./DuplicateAccordion";

interface FormInputs {
  csvFile: FileList;
}

const CSVUploader = () => {
  const { refetch } = useImportedVends();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isDragActive, setIsDragActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileError, setFileError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  const csvUploadMutation = useMutation<
    CSVUploadResponse,
    CSVUploadError,
    File
  >(uploadVendCSV);
  const validateCSV = (files: FileList | null) => {
    if (files && files?.length > 0) {
      const file = files[0];
      const isValid =
        file.type === CSV_MIME_TYPE || file.name.endsWith(CSV_FILE_EXTENSION);
      setIsValidFile(isValid);
      setFileError(isValid ? null : t("pages-vends-invalid-file-type"));
      return isValid || t("pages-vends-valid");
    }
    setIsValidFile(false);
    setFileError(t("pages-vends-required"));
    return t("pages-vends-required");
  };
  const onSubmit = async (data: FormInputs) => {
    const file = data.csvFile[0];
    if (file && file instanceof File) {
      try {
        const result = await csvUploadMutation.mutateAsync(file);
        enqueueSnackbar(t("pages-vends-upload-success"), {
          variant: "success",
        });
        refetch();
      } catch (error) {
        if (error instanceof Error) {
          setFileError(t(error.message));
        } else {
          enqueueSnackbar(t("pages-vends-upload-error"), { variant: "error" });
        }
      }
    } else {
      enqueueSnackbar(t("pages-vends-upload-error"), { variant: "error" });
    }
  };
  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        margin: "auto",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box maxWidth={500} mx={"auto"}>
            <Controller
              name="csvFile"
              control={control}
              rules={{
                required: t("pages-vends-required"),
                validate: {
                  isCSV: (files) =>
                    files[0]?.type === CSV_MIME_TYPE ||
                    files[0]?.name.endsWith(CSV_FILE_EXTENSION),
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Paper
                  elevation={0}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragActive(true);
                  }}
                  onDragLeave={() => setIsDragActive(false)}
                  onDrop={(e) => {
                    e.preventDefault();
                    setIsDragActive(false);
                    const files = e.dataTransfer.files;
                    if (files?.length) {
                      onChange(files);
                      onBlur();
                      validateCSV(files);
                    }
                  }}
                  sx={{
                    bgcolor: isDragActive
                      ? theme.palette.background.light
                      : theme.palette.background.white,
                    borderStyle: isDragActive ? "solid" : "dashed",
                    padding: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    border: "1px dashed",
                    borderColor: theme.palette.border.main,
                    backgroundColor: theme.palette.background.default,
                    transition: "border .24s ease-in-out",
                  }}
                >
                  <Input
                    sx={{ display: "none" }}
                    type="file"
                    id="csv-upload-input"
                    ref={ref}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files?.length) {
                        onChange(e.target.files);
                        onBlur();
                        validateCSV(e.target.files);
                      }
                    }}
                  />
                  <label htmlFor="csv-upload-input">
                    <CloudUploadIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Typography variant="subtitle1" gutterBottom>
                      {t("pages-vends-drag")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("pages-vends-or")}
                    </Typography>
                    <Button variant="outlined" component="span" sx={{ mt: 2 }}>
                      {t("pages-vends-select-file")}
                    </Button>
                  </label>
                  {value && value[0] && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {t("pages-vends-selected-file")}: <i>{value[0].name}</i>
                    </Typography>
                  )}
                </Paper>
              )}
            />
            {(errors.csvFile || fileError) && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {errors.csvFile?.message || fileError}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={csvUploadMutation.isLoading || !isValidFile}
              sx={{
                my: 2,
                height: 48,
                fontSize: 16,
              }}
            >
              {csvUploadMutation.isLoading
                ? t("pages-vends-uploading-file")
                : t("pages-vends-upload-file")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {csvUploadMutation.isSuccess &&
            csvUploadMutation.data?.detected_duplicates &&
            csvUploadMutation.data.detected_duplicates?.length > 0 && (
              <DuplicateVendsAccordion
                duplicates={csvUploadMutation.data.detected_duplicates}
                expanded={expanded}
                onAccordionChange={handleAccordionChange}
              />
            )}
          {csvUploadMutation.isError && csvUploadMutation.error && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="error">
                Upload failed: {csvUploadMutation.error.message}
              </Typography>
              {csvUploadMutation.error.errors &&
                Object.entries(csvUploadMutation.error.errors).map(
                  ([key, errors]) => (
                    <Typography key={key} variant="body2" color="error">
                      {key}: {errors.join(", ")}
                    </Typography>
                  ),
                )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CSVUploader;
