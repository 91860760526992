import { enqueueSnackbar } from "notistack";
import { startTransition } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useCustomNavigation } from "../../hooks/useCustomNavigation";
import { ROUTES_CONFIG } from "../../routes/routesConfig";
import { IconName } from "../common/CustomIcon";
import CustomListItem from "./CustomListItem";
import CustomListItemButton from "./CustomListItemButton";

interface LogoutButtonProps {
  title: string;
  icon: IconName;
  disabled: boolean;
}

const LogoutButton = ({ title, icon, disabled }: LogoutButtonProps) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { navigateToLogin } = useCustomNavigation();
  const route = ROUTES_CONFIG.APP.routes.LOGIN.path;

  const handleLogout = async () => {
    try {
      enqueueSnackbar(t("global-logout-success"), {
        variant: "success",
        key: "success-snack-logout",
      });
      await logout();
      startTransition(() => {
        navigateToLogin();
      });
    } catch (error) {
      enqueueSnackbar("Error", {
        variant: "error",
        key: t("global-logout-error"),
      });
    }
  };

  return (
    <CustomListItem route={route} disabled={disabled}>
      <CustomListItemButton
        title={title}
        route={route}
        icon={icon}
        disabled={disabled}
        onClick={handleLogout}
      />
    </CustomListItem>
  );
};

export default LogoutButton;
