import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONFIG } from "../routes/routesConfig";

export const useCustomNavigation = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.LOGIN.path);
    });
  };
  const navigateToProducts = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.PRODUCTS.path);
    });
  };
  const navigateToDashboard = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.path);
    });
  };
  const navigateToAddUser = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.SETTINGS.routes.ADD_USER.path);
    });
  };
  const navigateToVends = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.VENDS.path);
    });
  };
  const navigateToCSVUpload = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.VENDS.routes.CSV_UPLOAD.path);
    });
  };
  const navigateToSettings = () => {
    startTransition(() => {
      navigate(ROUTES_CONFIG.APP.routes.SETTINGS.path);
    });
  };

  return {
    navigateToLogin,
    navigateToDashboard,
    navigateToProducts,
    navigateToAddUser,
    navigateToVends,
    navigateToCSVUpload,
    navigateToSettings,
  };
};
