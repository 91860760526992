import React from "react";

import { Slide, SlideProps } from "@mui/material";
import { useScrollBehavior } from "../../hooks/useScrollbehavior";
interface HideOnScrollProps {
  children: React.ReactElement;
}

const HideOnScroll = ({ children }: HideOnScrollProps) => {
  const { isScrolled, isScrollingUp } = useScrollBehavior();
  const slideProps: SlideProps = {
    children,
    appear: false,
    direction: "down",
    in: !isScrolled || isScrollingUp,
    timeout: isScrolled && isScrollingUp ? 50 : undefined,
  };
  return <Slide {...slideProps}>{children}</Slide>;
};

export default HideOnScroll;
