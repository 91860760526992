import { Grid } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generalProductFormFields } from "../../config/forms/productFormFields";
import { UOM_FIELDS } from "../../config/forms/uomFields";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import CustomSelect from "../common/CustomSelect";
import CustomTextField from "../common/CustomTextField";

const GeneralProductDataForm: React.FC = () => {
  const { t } = useTranslation();
  const { smDown } = useBreakpoint();
  const { control } = useFormContext();

  return (
    <Grid container spacing={smDown ? 2 : 3}>
      {generalProductFormFields.map((field) => (
        <Grid item xs={field.gridWidth} key={field.name}>
          <CustomTextField
            control={control}
            name={field.name}
            label={t(field.label)}
            icon={field.icon}
            required={field.required}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <CustomSelect
          name="uom"
          label={t("pages-products-add-product-form-uom")}
          control={control}
          selectFields={UOM_FIELDS}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralProductDataForm;
