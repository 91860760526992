import { InfoOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

interface CustomInfoBoxProps {
  label: any;
  title: string;
}

const CustomInfoBox = ({ label, title }: CustomInfoBoxProps) => {
  return (
    <span>
      <Box
        sx={{
          pt: 0.5,
          pl: 0.25,
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoOutlined sx={{ mr: 0.5, color: "action.active" }} />
        <Typography
          variant="caption"
          sx={{ color: "action.active", lineHeight: 1.25 }}
        >
          {label}
        </Typography>
      </Box>
    </span>
  );
};

export default CustomInfoBox;
