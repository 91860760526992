import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import ProductList from "../../../components/products/ProductList";
import ContentBox from "../../../components/common/ContentBox";
import CustomButton from "../../../components/common/CustomButton";
import CustomButtonBox from "../../../components/common/CustomButtonBox";
import { ROUTES_CONFIG } from "../../../routes/routesConfig";

export default function ProductsPage() {
  const navigate = useNavigate();
  const handleAddProductRedirectClick = () => {
    navigate(ROUTES_CONFIG.APP.routes.PRODUCTS.routes.ADD_PRODUCT.path);
  };
  return (
    <ContentBox
      pageId="products-page"
      pageTitle={"pages-products-title"}
      pageHeaderHasActions={true}
      pageHeaderAction={
        <CustomButtonBox>
          <CustomButton
            customButtonId="add-product-button"
            customButtonText="pages-products-add-new"
            customButtonPrimary={true}
            customButtonIcon={<AddCircleOutlineOutlinedIcon />}
            customButtonFunc={handleAddProductRedirectClick}
          />
        </CustomButtonBox>
      }
      pageContent={<ProductList />}
    />
  );
}
