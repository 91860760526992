import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { inboundLogisticsFields } from "../../config/forms/logisticsFormFields";
import {
  addressFields,
  supplierFields,
} from "../../config/forms/supplierFormFields";
import CustomFormSectionTitle from "../common/CustomFormSectionTitle";
import CustomTextField from "../common/CustomTextField";

const AddSupplierForm = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  return (
    <Grid
      container
      spacing={4}
      sx={{
        py: 1,
      }}
    >
      <Grid item xs={12}>
        <CustomFormSectionTitle
          pB={2}
          text={t("pages-products-add-supplier-form-general-data")}
        />
        <Grid container rowSpacing={3} columnSpacing={1.5}>
          {supplierFields.map((field) => {
            let placeholder = field.placeholder || "";
            if (placeholder && typeof placeholder.format === "function") {
              const watchedValue = watch(field.placeholder.field);
              placeholder = placeholder.format(watchedValue);
            }
            return (
              <Grid item xs={12} sm={field.gridWidth} key={field.name}>
                <CustomTextField
                  name={field.name}
                  control={control}
                  label={t(field.label)}
                  icon={field.icon}
                  required={field.required}
                  placeholder={placeholder}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>{" "}
      <Grid item xs={12}>
        <CustomFormSectionTitle
          pB={1}
          text={t("pages-products-add-supplier-form-logistics-data")}
        />
        <Controller
          name="default_inbound_logistics_type"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <Select {...field}>
                {inboundLogisticsFields.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {t(type.label)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message || ""}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomFormSectionTitle
          pB={1.5}
          text={t("pages-products-add-supplier-form-address-data")}
        />
        <Grid container rowSpacing={3} columnSpacing={1.5}>
          {addressFields.map((field) => (
            <Grid item xs={field.gridWidth} key={field.name}>
              <CustomTextField
                name={field.name}
                control={control}
                label={t(field.label)}
                icon={field.icon}
                required={field.required}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddSupplierForm;
