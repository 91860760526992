import { SvgIconComponent } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useBreakpoint } from "../../hooks/useBreakpoint";

interface CustomTextFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  icon: SvgIconComponent;
  placeholder?: string;
  required?: boolean;
}

const CustomTextField = ({
  name,
  control,
  label,
  icon: Icon,
  required,
  placeholder,
}: CustomTextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const { smDown } = useBreakpoint();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={field.value || ""}
          label={required ? `${label} *` : label}
          fullWidth
          placeholder={placeholder}
          error={!!error}
          helperText={error?.message || ""}
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            setIsFocused(false);
            field.onBlur();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  color={error ? "error" : isFocused ? "primary" : "action"}
                  fontSize={smDown ? "small" : "medium"}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomTextField;
