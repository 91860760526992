import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { DESKTOP_CONTAINER_HEADER_HEIGHT } from "../../config/style/theme";
import StickyHeader from "./StickyHeader";

interface ContentContainerProps {
  pageId: string;
  pageTitle: string;
  pageHeaderHasActions?: boolean;
  pageHeaderAction?: React.ReactNode;
  pageContent?: React.ReactNode;
}

const ContentBox = ({
  pageId,
  pageTitle,
  pageHeaderHasActions,
  pageHeaderAction,
  pageContent,
}: ContentContainerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { smDown } = useBreakpoint();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    border: smDown ? "none" : `1px solid ${theme.palette.border.main}`,
    height: "100%",
    overflow: "hidden",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    background: theme.palette.background.white,
  };

  const containerHeaderStyle = {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: 2,
    width: "100%",
    height: smDown ? 56 : DESKTOP_CONTAINER_HEADER_HEIGHT,
    flexShrink: 0,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.white,
  };

  const containerContentStyle = {
    flexGrow: 1,
    padding: 2,
    paddingTop: smDown ? theme.spacing(9) : theme.spacing(2),
    background: theme.palette.background.white,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  const headerContent = (
    <Box sx={containerHeaderStyle}>
      <Typography
        variant={smDown ? "h5" : "h4"}
        fontWeight="bold"
        component="h4"
        align="left"
      >
        {t(pageTitle)}
      </Typography>
      {pageHeaderHasActions && <Box>{pageHeaderAction}</Box>}
    </Box>
  );

  return (
    <Box key={pageId} sx={containerStyle}>
      {smDown ? (
        <StickyHeader initialTop={56}>{headerContent}</StickyHeader>
      ) : (
        headerContent
      )}
      <Box sx={containerContentStyle}>{pageContent}</Box>
    </Box>
  );
};

export default ContentBox;
