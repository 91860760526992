import { Chip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createGetRoleDisplayName, getRoleColor } from "../../../utils/helpers";
interface UserRoleChipProps {
  role: string;
}

const UserRoleChip = ({ role }: UserRoleChipProps) => {
  const { t } = useTranslation();
  const getRoleDisplayName = createGetRoleDisplayName(t);
  const theme = useTheme();
  return (
    <Chip
      key={role}
      label={getRoleDisplayName(t(role))}
      size="small"
      style={{
        backgroundColor: getRoleColor(role, theme),
        color: theme.palette.background.white,
        marginRight: 5,
      }}
    />
  );
};

export default UserRoleChip;
