import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import React from "react";
import CustomCard from "../common/CustomCard";
import SupplierSelectElement from "./SupplierSelectElement";

interface SupplierSelectProps {
  handleAddSupplier: () => void;
}

const SupplierSelectCard: React.FC<SupplierSelectProps> = ({
  handleAddSupplier,
}) => {
  return (
    <CustomCard
      cardId="supplier-select-card"
      cardButtonTitle="pages-products-add-supplier-form-title"
      cardTitle="pages-products-add-supplier-card-title"
      cardButtonIcon={<AddCircleOutlineOutlinedIcon />}
      cardButtonFunc={handleAddSupplier}
      hasNoBorder={false}
      hideButtonTitle={false}
      cardContent={<SupplierSelectElement />}
    />
  );
};

export default SupplierSelectCard;
