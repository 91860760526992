import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface CustomTooltipProps extends Omit<TooltipProps, "children"> {
  children: React.ReactElement;
  showtooltip: boolean;
  disabled?: boolean;
  disabledText?: string;
}

const CustomTooltip = ({
  children,
  showtooltip,
  disabled = false,
  disabledText = "global-tooltip-disabled",
  ...props
}: CustomTooltipProps) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const translatedDisabledText = t(disabledText);

  if (!disabled && !showtooltip) {
    return children;
  }

  return (
    <Tooltip
      {...props}
      title={disabled ? translatedDisabledText : props.title}
      componentsProps={{
        tooltip: {
          sx: {
            bgColor: theme.palette.background.black,
            color: theme.palette.background.white,
            borderRadius: 1,
          },
        },
      }}
      slotProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              py: 0,
            },
          },
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      {React.cloneElement(children, { disabled })}
    </Tooltip>
  );
};

export default CustomTooltip;
