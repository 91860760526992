import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import theme from "../../config/style/theme";
import CustomTooltip from "./CustomTooltip";

interface CustomCardProps {
  cardId: string;
  cardTitle: string;
  hideButtonTitle?: boolean;
  cardHasButton?: boolean;
  cardButtonPrimary?: boolean;
  cardButtonFunc?: () => void;
  cardButtonTitle?: string;
  cardButtonIcon?: React.ReactNode;
  cardHasSecondaryButton?: boolean;
  cardSecondaryButtonPrimary?: boolean;
  cardSecondaryButtonFunc?: () => void;
  cardSecondaryButtonTitle?: string;
  cardSecondaryButtonIcon?: React.ReactNode;
  cardSecondaryButtonDisabled?: boolean;
  cardContent?: React.ReactNode;
  hasNoBorder?: boolean;
  tooltipTitlePrimaryButton?: React.ReactNode;
  tooltipTitleSecondaryButton?: React.ReactNode;
  tooltipDisabled?: boolean;
  headerHasBorder?: boolean;
}

const CustomCard = ({
  cardId,
  cardTitle,
  hideButtonTitle,
  cardHasButton = true,
  cardButtonPrimary = true,
  cardButtonFunc,
  cardButtonTitle,
  cardButtonIcon,
  cardHasSecondaryButton,
  cardSecondaryButtonTitle,
  cardSecondaryButtonFunc,
  cardSecondaryButtonPrimary,
  cardSecondaryButtonIcon,
  cardSecondaryButtonDisabled,
  cardContent,
  hasNoBorder = false,
  tooltipTitlePrimaryButton,
  tooltipTitleSecondaryButton,
  tooltipDisabled = false,
  headerHasBorder = false,
}: CustomCardProps) => {
  const { t } = useTranslation();
  const { smDown } = useBreakpoint();
  const customCardStyles = {
    border: hasNoBorder ? "none" : `1px solid ${theme.palette.border.main}`,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
  };
  const { lgDown } = useBreakpoint();
  return (
    <Card key={cardId} variant="outlined" sx={customCardStyles}>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{
          pr: 2,
          borderBottom: headerHasBorder ? "1px solid" : "none",
          borderColor: headerHasBorder ? "border.main" : "transparent",
        }}
      >
        <Grid item sx={{ p: 2 }}>
          <Typography fontWeight={"bold"} variant="h5">
            {t(cardTitle)}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            gap: cardHasSecondaryButton ? 1 : 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cardHasButton && cardButtonTitle && (
            <Button
              onClick={cardButtonFunc}
              sx={{
                minWidth: 40,
                width: smDown || hideButtonTitle ? 40 : "auto",
                height: smDown || hideButtonTitle ? 40 : "auto",
                textTransform: "capitalize",
                fontWeight: "bold",
                borderRadius: 1,
              }}
              size="small"
              variant={cardButtonPrimary ? "contained" : "outlined"}
            >
              <Box
                sx={{
                  display: "grid",
                  placeContent: "center",
                  pr: smDown || hideButtonTitle ? 0 : 1,
                }}
              >
                <CustomTooltip
                  title={tooltipTitlePrimaryButton}
                  disabled={tooltipDisabled}
                  showtooltip={lgDown ? true : false}
                >
                  <Box sx={{ display: "grid", placeContent: "center" }}>
                    {cardButtonIcon}
                  </Box>
                </CustomTooltip>
              </Box>
              {smDown || hideButtonTitle ? "" : t(cardButtonTitle)}
            </Button>
          )}
          {cardHasSecondaryButton && cardSecondaryButtonTitle && (
            <Button
              onClick={cardSecondaryButtonFunc}
              disabled={cardSecondaryButtonDisabled}
              sx={{
                minWidth: 40,
                width: smDown || hideButtonTitle ? 40 : "auto",
                height: smDown || hideButtonTitle ? 40 : "auto",
                textTransform: "capitalize",
                fontWeight: "bold",
                borderRadius: 1,
              }}
              size="small"
              variant={cardSecondaryButtonPrimary ? "contained" : "outlined"}
            >
              <Box
                sx={{
                  display: "grid",
                  placeContent: "center",
                  pr: smDown || hideButtonTitle ? 0 : 1,
                }}
              >
                <CustomTooltip
                  title={tooltipTitleSecondaryButton}
                  disabled={tooltipDisabled}
                  showtooltip={lgDown ? true : false}
                >
                  <Box sx={{ display: "grid", placeContent: "center" }}>
                    {cardSecondaryButtonIcon}
                  </Box>
                </CustomTooltip>
              </Box>
              {smDown || hideButtonTitle ? "" : t(cardSecondaryButtonTitle)}
            </Button>
          )}
        </Grid>
      </Grid>
      {cardContent && (
        <CardContent
          sx={{
            pt: smDown ? 0.75 : 1,
            pb: 0,
            px: 2,
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {cardContent}
        </CardContent>
      )}
    </Card>
  );
};

export default CustomCard;
