import { Box, Typography } from "@mui/material";

interface CustomFormSectionTitleProps {
  pB: number;
  text: string;
}

const CustomFormSectionTitle = ({ pB, text }: CustomFormSectionTitleProps) => {
  return (
    <Box sx={{ height: "fit-content", width: "100%", pb: pB, pt: 0 }}>
      <Typography variant="body1" fontWeight={"bold"}>
        {text}:
      </Typography>
    </Box>
  );
};

export default CustomFormSectionTitle;
