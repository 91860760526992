import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  lighten,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AUTH_SUB_PATH } from "../../api/services/auth";
import { BASE_URL } from "../../api/services/axiosClient";
import { ReactComponent as BiobringerLogoSVG } from "../../assets/svg/logos/biobringer_logo_large_dark.svg";
import { ReactComponent as GoogleIconColored } from "../../assets/svg/logos/google_icon_colored.svg";
import { LoginData, useAuth } from "../../hooks/useAuth";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { ROUTES_CONFIG } from "../../routes/routesConfig";
import { findRoutePerPath } from "../../routes/utils/routesUtil";
interface LoginPageProps {}

export default function LoginPage(props: LoginPageProps) {
  const theme = useTheme();
  const { smDown } = useBreakpoint();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [loginError, setLoginError] = useState(false);
  const [googleLoginError, setGoogleLoginError] = useState(false);
  const { control, handleSubmit, formState, trigger, setError } =
    useForm<LoginData>({
      mode: "onSubmit",
    });
  const { errors, isSubmitting, touchedFields, submitCount } = formState;

  function getReturnPath() {
    const returnPath = searchParams.get("returnPath");
    let redirectPath: string = ROUTES_CONFIG.APP.routes.REFILL.path;
    if (returnPath !== null) {
      const resolvedRoute = findRoutePerPath(ROUTES_CONFIG, returnPath);
      if (resolvedRoute !== null) {
        redirectPath = resolvedRoute.path;
      }
    }
    return redirectPath;
  }

  const handleSignupRedirect = () => {
    alert("TODO:Implement redirect to sign-up form");
  };

  const handleOnSubmit = async (loginData: LoginData) => {
    try {
      await login(loginData);
      const redirectPath = getReturnPath();
      navigate(redirectPath);
    } catch (e: any) {
      setLoginError(true);
      // setError('username', { type: 'custom', message: t(`pages-login-err-${e.message}`) });
      // setError('password', { type: 'custom', message: t(`pages-login-err-${e.message}`) });
    }
  };

  function getGoogleSuccessLoginReturnUrl() {
    const redirectPath = getReturnPath();
    return encodeURIComponent(
      `${window.location.protocol}//${window.location.host}${redirectPath}`,
    );
  }

  function getGoogleErrorLoginReturnUrl() {
    return encodeURIComponent(
      `${window.location.protocol}//${window.location.host}/login?error=err-google-login`,
    );
  }

  useEffect(() => {
    const error = searchParams.get("error");
    if (error !== null && error === "err-google-login") {
      setGoogleLoginError(true);
    }
  }, []);

  function getGoogleOidcUrl() {
    return `${BASE_URL}${AUTH_SUB_PATH}/login/google?return_success_url=${getGoogleSuccessLoginReturnUrl()}&return_failure_url=${getGoogleErrorLoginReturnUrl()}`;
  }

  // function navigateToReturnUrl () {
  //     const queryParams = queryString.parse(location.search);
  //     // const returnUrl = getStrict(queryParams, 'returnUrl', routes.INDEX.path);
  //     navigate(returnUrl);
  // }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: smDown
          ? theme.palette.background.white
          : theme.palette.background.light,
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          maxWidth: "380px",
          minWidth: "300px",
          px: 3,
          pt: 4,
          pb: 3,
          border: smDown ? "none" : `1px solid ${theme.palette.border.main}`,
        }}
      >
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "360px",
              marginX: "auto",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <BiobringerLogoSVG
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                typography: { sm: "h5", xs: "h6" },
                pb: { xs: 4 },
              }}
              px={2}
              pt={1}
              textAlign="center"
              color={"#000000"}
            >
              <b>{t("pages-login-app-name")}</b>
            </Typography>
          </Box>
          {loginError && (
            <Box pt={2} pb={2}>
              <Alert severity="error">
                <AlertTitle>{t("global-error")}</AlertTitle>
                {t("pages-login-error-login")}
              </Alert>
            </Box>
          )}

          <Controller
            name="username"
            control={control}
            rules={{
              required: t("pages-login-username-err-req"),
            }}
            render={({ field }) => {
              return (
                <Box>
                  <TextField
                    {...field}
                    label={t("pages-login-username")}
                    inputProps={{
                      "aria-label": "username",
                      autoComplete: "username",
                    }}
                    fullWidth
                    autoFocus={true}
                    variant="outlined"
                    sx={{ input: { color: "primary.dark" } }}
                    error={
                      !!errors.username &&
                      (touchedFields.username || submitCount > 0)
                    }
                    helperText={
                      errors.username?.message &&
                      (touchedFields.username || submitCount > 0)
                        ? t(errors.username.message as any)
                        : ""
                    }
                  />
                </Box>
              );
            }}
          />

          <Controller
            name="password"
            control={control}
            rules={{
              required: t("pages-login-password-err-req"),
            }}
            render={({ field }) => {
              return (
                <Box pt={2}>
                  <TextField
                    {...field}
                    label={t("pages-login-password")}
                    type="password"
                    inputProps={{
                      "aria-label": "Password",
                      autoComplete: "current-password",
                    }}
                    variant="outlined"
                    fullWidth
                    autoFocus={false}
                    error={
                      !!errors.password &&
                      (touchedFields.password || submitCount > 0)
                    }
                    helperText={
                      errors.password?.message &&
                      (touchedFields.password || submitCount > 0)
                        ? t(errors.password.message as any)
                        : ""
                    }
                  />
                </Box>
              );
            }}
          />
          <Box pt={5} pb={1}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitting}
              sx={{
                boxShadow: "none",
              }}
            >
              {t("pages-login-btn-login")}
            </Button>
          </Box>
        </form>
        {googleLoginError && (
          <Box pt={2} pb={2}>
            <Alert severity="error">
              <AlertTitle>{t("global-error")}</AlertTitle>
              {t("pages-login-error-google-login")}
            </Alert>
          </Box>
        )}
        <Box pt={1} pb={1}>
          <Button
            size="large"
            sx={{
              backgroundColor: theme.palette.background.black,
              color: theme.palette.background.white,
              "&:hover": {
                backgroundColor: lighten("#000000", 0.3),
              },
            }}
            fullWidth
            href={getGoogleOidcUrl()}
            startIcon={<GoogleIconColored />}
          >
            {t("pages-login-btn-login-google")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
