import {
  Apartment,
  Business,
  Email,
  Flag,
  Home,
  HomeWork,
  Info,
  LocationCity,
  Phone,
  PhoneIphone,
  PinDrop,
  SvgIconComponent,
  WavingHand,
  Work,
} from "@mui/icons-material";

interface FieldConfig {
  name: string;
  label: string;
  icon: SvgIconComponent;
  gridWidth: number;
  required: boolean;
  placeholder?: any;
}

export const supplierFields: FieldConfig[] = [
  {
    name: "name",
    label: "pages-products-add-supplier-form-name",
    icon: Work,
    gridWidth: 12,
    required: true,
  },
  {
    name: "greeting",
    label: "pages-products-add-supplier-form-greeting",
    icon: WavingHand,
    gridWidth: 12,
    required: true,
    placeholder: {
      field: "name",
      format: (value: any) => `Hallo ${value}! Anbei die neue Bestellliste.`,
    },
  },
  {
    name: "email",
    label: "pages-settings-email",
    icon: Email,
    gridWidth: 12,
    required: true,
  },
  {
    name: "mobile_number",
    label: "pages-products-add-supplier-form-mobile_number",
    icon: PhoneIphone,
    gridWidth: 6,
    required: true,
  },
  {
    name: "phone_number",
    label: "pages-products-add-supplier-form-phone_number",
    icon: Phone,
    gridWidth: 6,
    required: false,
  },
];

export const addressFields: FieldConfig[] = [
  {
    name: "street",
    label: "pages-products-add-address-form-street",
    icon: Home,
    gridWidth: 12,
    required: true,
  },
  {
    name: "house_number",
    label: "pages-products-add-address-form-house_number",
    icon: Business,
    gridWidth: 4,
    required: true,
  },
  {
    name: "apartment_number",
    label: "pages-products-add-address-form-apartment_number",
    icon: Apartment,
    gridWidth: 4,
    required: true,
  },
  {
    name: "floor",
    label: "pages-products-add-address-form-floor",
    icon: HomeWork,
    gridWidth: 4,
    required: false,
  },
  {
    name: "zipcode",
    label: "pages-products-add-address-form-zipcode",
    icon: PinDrop,
    gridWidth: 4,
    required: true,
  },
  {
    name: "city",
    label: "pages-products-add-address-form-city",
    icon: LocationCity,
    gridWidth: 8,
    required: true,
  },
  {
    name: "country",
    label: "pages-products-add-address-form-country",
    icon: Flag,
    gridWidth: 12,
    required: true,
  },
  {
    name: "additional_information",
    label: "pages-products-add-address-form-additional_information",
    icon: Info,
    gridWidth: 12,
    required: false,
  },
];
