import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useUsers } from "../../../hooks/useUserManagement";
import CustomErrorBox from "../../common/CustomErrorBox";
import MessageBox from "../../common/MessageBox";
import DeleteUser from "./DeleteUser";
import UserListItem from "./UserListItem";

const UserList = () => {
  const { data: users, isLoading, error, refetch } = useUsers();
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  if (isLoading)
    return (
      <MessageBox>
        <CircularProgress />
      </MessageBox>
    );
  if (error)
    return <CustomErrorBox errorMessage={"pages-settings-user-fetch-error"} />;

  const handleDelete = (user: any) => {
    setSelectedUser(user);
    setOpenDeleteUserDialog(true);
  };

  const handleCloseDeleteUserDialog = () => {
    setOpenDeleteUserDialog(false);
    setSelectedUser(null);
  };

  const handleDeleteSuccess = () => refetch();

  return (
    <>
      {users?.map((user) => (
        <UserListItem key={user.id} user={user} onDelete={handleDelete} />
      ))}
      {selectedUser && (
        <>
          <DeleteUser
            open={openDeleteUserDialog}
            user={selectedUser}
            handleClose={handleCloseDeleteUserDialog}
            onDeleteSuccess={handleDeleteSuccess}
          />
        </>
      )}
    </>
  );
};

export default UserList;
