import { useQuery } from "@tanstack/react-query";
import {
  getDefaultLogisticsProvider,
  LogisticsProviderItem,
} from "../api/logisticsProvider";

export const useDefaultLogisticProvider = () => {
  return useQuery<LogisticsProviderItem, Error>({
    queryKey: ["defaultLogisticProvider"],
    queryFn: getDefaultLogisticsProvider,
  });
};
