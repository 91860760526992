import UserManagement from "../../components/settings/user-management/UserManagement";
import ContentBox from "../../components/common/ContentBox";

export default function SettingsPage() {
  return (
    <ContentBox
      pageId="vends-page"
      pageTitle={"pages-settings"}
      pageHeaderHasActions={true}
      pageContent={<UserManagement />}
    />
  );
}
