import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { addProduct, AddProductFormData, deleteProduct } from "../api/product";
import { useProductList } from "./useProduct";

export const useProductMutations = () => {
  const { t } = useTranslation();
  const { refetch } = useProductList();

  const addProductMutation = useMutation({
    mutationFn: (data: AddProductFormData) => addProduct(data),
    onSuccess: () => {
      enqueueSnackbar(t("pages-products-add-success"), {
        variant: "success",
      });
      refetch();
    },
    onError: (error: any) => {
      console.error("Full error object:", error);
      let errorMessage = t("pages-products-add-error");
      if (error.response?.data?.message) {
        errorMessage += `: ${error.response.data.message}`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });

  const deleteProductMutation = useMutation({
    mutationFn: (data: string) => deleteProduct(data),
    onSuccess: () => {
      enqueueSnackbar(t("pages-products-delete-success"), {
        variant: "success",
      });
      refetch();
    },
    onError: (error: any) => {
      console.error("Full error object:", error);
      let errorMessage = t("pages-products-delete-error");
      if (error.response?.data?.message) {
        errorMessage += `: ${error.response.data.message}`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });
  return {
    addProductMutation,
    deleteProductMutation,
  };
};
