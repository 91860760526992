import { Description, Inventory, SvgIconComponent } from "@mui/icons-material";

interface FormFieldConfig {
  name: string;
  label: string;
  icon: SvgIconComponent;
  gridWidth: number;
  required: boolean;
}

export const generalProductFormFields: FormFieldConfig[] = [
  {
    name: "description",
    label: "pages-products-add-product-form-description",
    icon: Description,
    gridWidth: 12,
    required: true,
  },
  {
    name: "sku",
    label: "pages-products-add-product-form-sku",
    icon: Inventory,
    gridWidth: 12,
    required: true,
  },
  // {
  //   name: "uom",
  //   label: "pages-products-add-product-form-uom",
  //   icon: Straighten,
  //   gridWidth: 12,
  // },
];
