import * as yup from "yup";
import { TranslationFunction } from "../helpers";

const createAddSupplierSchema = (t: TranslationFunction) => {
  return yup.object().shape({
    name: yup.string().required(t("global-validation-required")),
    greeting: yup.string().required(t("global-validation-required")),
    email: yup
      .string()
      .lowercase()
      .email(t("pages-settings-validation-email"))
      .required(t("pages-settings-validation-required")),
    phone_number: yup.string().min(6, t("global-validation-min-6")),
    mobile_number: yup.string().min(8, t("global-validation-min-8")),
    default_inbound_logistics_type: yup
      .string()
      .required(t("global-validation-required")),
    street: yup.string().required(t("global-validation-required")),
    house_number: yup.string().required(t("global-validation-required")),
    apartment_number: yup.string(),
    floor: yup.string(),
    city: yup.string().required(t("global-validation-required")),
    zipcode: yup
      .string()
      .required(t("global-validation-required"))
      .min(4, t("global-validation-not-valid")),
    country: yup.string().required(t("global-validation-required")),
    additional_information: yup.string(),
  });
};

export default createAddSupplierSchema;
