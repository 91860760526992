import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import biobringerLogoSmall from "../../assets/png/logos/biobringer_logo_small_dark.png";
import { useAuth } from "../../hooks/useAuth";
import { ROUTES_CONFIG } from "../../routes/routesConfig";
import { bottomNavigationElements } from "../../config/navigation/navigationElements";
import CustomIcon from "../common/CustomIcon";
import CustomTooltip from "../common/CustomTooltip";
import { MenuItem } from "./MenuItem";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export default function AccountMenu() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { lgDown } = useBreakpoint();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleOpenAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      enqueueSnackbar(t("global-logout-success"), {
        variant: "success",
        key: "success-snack-logout",
      });
      await logout();
      navigate(ROUTES_CONFIG.APP.routes.LOGIN.path);
    } catch (error) {
      console.log(error, "error logging out");
      enqueueSnackbar("Error", {
        variant: "error",
        key: t("global-logout-error"),
      });
    }
  };
  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <CustomTooltip
          disabled={false}
          showtooltip={lgDown ? true : false}
          title={t("components-accountmenu-tooltip")}
        >
          <IconButton
            onClick={handleOpenAccountMenuClick}
            size="small"
            sx={{ p: 0 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                bgcolor: "background.light",
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                  p: 1,
                },
              }}
              alt="User Avatar"
              src={biobringerLogoSmall}
            />
          </IconButton>
        </CustomTooltip>
        <Menu
          sx={{ mt: "40px" }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              px: 1,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            },
          }}
        >
          {bottomNavigationElements.map((navigationElement) => (
            <MenuItem
              key={navigationElement.title}
              title={t(navigationElement.title)}
              route={navigationElement.route}
              open={Boolean(anchorEl)}
            >
              <CustomIcon
                icon={navigationElement.icon}
                sx={{ color: "common.black" }}
                isActive={false}
                size={25}
              />
            </MenuItem>
          ))}
          <ListItemButton
            onClick={handleLogout}
            sx={{
              borderRadius: 1,
              justifyContent: Boolean(anchorEl) ? "initial" : "center",
            }}
          >
            <ListItemIcon>
              <CustomIcon
                icon={"logout"}
                sx={{ color: "common.black" }}
                isActive={false}
                size={25}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("global-logout")}
              sx={{ color: "common.black" }}
            />
          </ListItemButton>
        </Menu>
      </Box>
    </>
  );
}
