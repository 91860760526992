import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

interface MessageBoxProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}
const MessageBox = ({ children, sx }: MessageBoxProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default MessageBox;
