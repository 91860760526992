import { Settings } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductListItem } from "../../api/product";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useProductList } from "../../hooks/useProduct";
import { useProductMutations } from "../../hooks/useProductMutations";
import CustomErrorBox from "../common/CustomErrorBox";
import CustomTableFooter from "../common/CustomTableFooter";
import CustomTooltip from "../common/CustomTooltip";
import MessageBox from "../common/MessageBox";
import ProductListRowItem from "./ProductListRowItem";

type SortField = "description" | "sku";
type SortOrder = "asc" | "desc";

export default function ProductList() {
  const { t } = useTranslation();
  const { lgDown } = useBreakpoint();
  const { data: products, isLoading, error } = useProductList();
  const [sortField, setSortField] = useState<SortField>("description");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [filteredList, setFilteredList] = useState<ProductListItem[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { deleteProductMutation } = useProductMutations();
  const productListSettingsDisabled = true;
  const productListActionsDisabled = false;

  useEffect(() => {
    if (products) {
      let filtered = products.filter(
        (product) =>
          product?.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.sku.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      filtered.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });

      setFilteredList(filtered);
      setPage(0);
    }
  }, [products, sortField, sortOrder, searchTerm]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (product: ProductListItem) => {
    deleteProductMutation.mutate(product.id);
  };

  if (isLoading)
    return (
      <MessageBox>
        <CircularProgress />
      </MessageBox>
    );
  if (error)
    return <CustomErrorBox errorMessage={"pages-settings-user-fetch-error"} />;
  return (
    <>
      {products && (
        <>
          <Box
            sx={{
              pb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder={t("pages-products-list-search-product")}
              variant="outlined"
              size="small"
              sx={{ width: 250 }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomTooltip
                disabled={productListSettingsDisabled}
                title={t("global-settings")}
                showtooltip={lgDown ? true : false}
              >
                <span>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderColor: "border.main",
                      borderRadius: 1,
                    }}
                    disabled={productListSettingsDisabled}
                  >
                    <Settings />
                  </IconButton>
                </span>
              </CustomTooltip>
            </Box>
          </Box>
          <TableContainer
            sx={{
              borderRadius: 1,
              border: "1px solid",
              borderColor: "border.main",
            }}
          >
            <Table size={"small"}>
              <TableHead sx={{ height: 50 }}>
                <TableRow>
                  <TableCell
                    width={100}
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    SKU
                  </TableCell>
                  <TableCell
                    width="100%"
                    align="left"
                    sx={{ fontWeight: "bold" }}
                  >
                    <TableSortLabel
                      active={sortField === "description"}
                      direction={
                        sortField === "description" ? sortOrder : "asc"
                      }
                      onClick={() => handleSort("description")}
                    >
                      {t("components-refill-product-table-header-description")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width={50} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((product) => (
                    <ProductListRowItem
                      key={product.id}
                      product={product}
                      onDelete={handleDelete}
                      actionsDisabled={productListActionsDisabled}
                    />
                  ))}
              </TableBody>
              <CustomTableFooter
                count={filteredList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                colSpan={3}
                labelRowsPerPage={"pages-products-list-rows-per-page"}
              />
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
