import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import axiosClient, { handleAxiosError } from "./services/axiosClient";

const SUB_PATH = "/user";

export interface User {
  id: string;
  email: string;
  username: string;
  password: string;
  roles: string[];
}
export type UserFormData = Omit<User, "id">;

export interface UserDataResponse {
  created_on: string;
  email: string;
  id: string;
  password: string;
  registered_on_: string;
  roles: string[];
  updated_on_: string;
  username: string;
}

export const addNewUser = async (
  userData: UserFormData,
): Promise<UserDataResponse> => {
  const newUserData: User = {
    ...userData,
    id: uuidv4(),
  };
  try {
    const response = await axiosClient.post<UserDataResponse>(
      `${SUB_PATH}`,
      newUserData,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};

export const getUsers = async (): Promise<UserDataResponse[]> => {
  try {
    const response: AxiosResponse<UserDataResponse[]> =
      await axiosClient.get(SUB_PATH);
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};

export const getUserDetails = async (
  userId: string,
): Promise<UserDataResponse[]> => {
  try {
    const response: AxiosResponse<UserDataResponse[]> = await axiosClient.get(
      `${SUB_PATH}/${userId}`,
    );
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};

export const deleteUser = async (
  userId: string,
): Promise<UserDataResponse[]> => {
  try {
    const response: AxiosResponse<UserDataResponse[]> =
      await axiosClient.delete(`${SUB_PATH}/${userId}`);
    return response.data;
  } catch (e) {
    throw handleAxiosError(`${SUB_PATH}`, e);
  }
};
