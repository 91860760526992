import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton } from "@mui/material";
import Navbar from "./Navbar";

interface MobileDrawerProps {
  open: boolean;
  onClose: () => void;
}

const MobileDrawer = ({ open, onClose }: MobileDrawerProps) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100%",
          position: "relative",
        },
      }}
    >
      <Box
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        sx={{ height: "100vh", position: "relative" }}
      >
        <Navbar />
        <IconButton
          onClick={onClose}
          sx={{
            top: 10,
            right: 5,
            margin: 1.5,
            position: "absolute",
            bgcolor: "background.light",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
